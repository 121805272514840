<template>
    <div class="statistics" v-if="loaded">
        <h3>Totaalscores</h3>
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th>Aantal</th>
                <th>Min</th>
                <th>Max</th>
                <th>Gemiddelde</th>
                <th>Variantie</th>
                <th>St. dev.</th>
                <th>Kwalificatie</th>
            </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Totaal</td>
                    <td>{{stats.total_scores.count}}</td>
                    <td>{{stats.total_scores.min}}</td>
                    <td>{{stats.total_scores.max}}</td>
                    <td>{{stats.total_scores.mean}}</td>
                    <td>{{stats.total_scores.variance}}</td>
                    <td>{{stats.total_scores.stdev}}</td>
                    <td>{{stats.total_scores.score_class}}</td>
                </tr>
            </tbody>
        </table>
        <h3>Scores per rol</h3>
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th>Aantal</th>
                <th>Min</th>
                <th>Max</th>
                <th>Gemiddelde</th>
                <th>Variantie</th>
                <th>St.Dev</th>
                <th>Kwalificatie</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="data in stats.role_scores">
                    <td>{{data.group_name}}</td>
                    <td>{{data.count}}</td>
                    <td>{{data.min}}</td>
                    <td>{{data.max}}</td>
                    <td>{{data.mean}}</td>
                    <td>{{data.variance}}</td>
                    <td>{{data.stdev}}</td>
                    <td>{{data.score_class}}</td>
                </tr>
            </tbody>
        </table>
       <h3>Scores per sectie</h3>
        <table class="table">
            <thead>
            <tr>
                <th></th>
                <th>Min</th>
                <th>Max</th>
                <th>Gemiddelde</th>
                <th>Variantie</th>
                <th>St.Dev</th>
                <th>Kwalificatie</th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="data in stats.section_scores">
                    <td>{{data.group_name}}</td>
                    <td>{{data.min}}</td>
                    <td>{{data.max}}</td>
                    <td>{{data.mean}}</td>
                    <td>{{data.variance}}</td>
                    <td>{{data.stdev}}</td>
                    <td>{{data.score_class}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "Stats",
        data() {
            return {
                loaded: false,
                stats: null
            }
        },

        mounted() {
            this.getStats();
        },

        props: ['baseUrl', 'moduleId'],

        methods: {
            getStats() {
                axios.get('/api/modules/'+this.moduleId+'/stats')
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.stats = response.data.data;
                            this.loaded = true;
                        } else {
                            this.errors.push(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },

        }
    }
</script>

<style scoped>

</style>
