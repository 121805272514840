import { render, staticRenderFns } from "./Stats.vue?vue&type=template&id=bade1f32&scoped=true"
import script from "./Stats.vue?vue&type=script&lang=js"
export * from "./Stats.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bade1f32",
  null
  
)

export default component.exports